(function () {

    'use strict';

    class ServiceOrderPreviewDialogCtrl {
        constructor($mdDialog, $translate, system, ServiceOrderService) {
            this.system = system;
            this.$mdDialog = $mdDialog;
            this.dateFormat = this.system['date_format'].js;
            this.translate=$translate;
            this.cancel = $mdDialog.cancel;
            this.service = ServiceOrderService;
            this.priorities_config = this.service.getPrioritiesConfig();

        }

        static get $inject() {
            return ["$mdDialog", "$translate", "system", "ServiceOrderService"];
        }

        $onInit() {
            this.codes = [];
            if (this.itemId) {
                this.promise = this.service.getServiceOrder(this.itemId)
                    .then(data => {
                        console.log(data)
                        this.item = data;
                    });
            }
        }

        cancel() {
            this.$mdDialog.cancel();
        }

        handlePayerLabel(value){
            switch (value){
                case 'P':
                    return this.translate.instant('payment_payer_patient');
                case 'T':
                    return this.translate.instant('payment_payer_third_party');
                case 'O':
                    return this.translate.instant('payment_payer_other');
                default:
                    return ''
            }
         }

    }

    module.exports = {
        template: require("./service-order-preview.tpl.html"),
        controllerAs: "vm",
        controller: ServiceOrderPreviewDialogCtrl,
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true,
        focusOnOpen: false

    }

})();