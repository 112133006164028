(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");


    const TICKET_STATUS = {
        "MISSED": {'color': '#f40d0d'},
        "REJECTED": {'color': '#1b2a8e'},
        "ACCEPTED": {'color': '#d717ec'},
        "IN_PROGRESS": {'color': '#659d06'},
        "COMPLETED": {'color': '#1abc09'},
        "ON_HOLD": {'color': '#1ca0fd'},
        "CANCELLED": {'color': '#2f00ff'},
        "SCHEDULED": {'color': '#a259fb'},
        "RESCHEDULED": {'color': '#8acc03'},
    }
    const PRIORITY_ICONS = {
        'LOW': {'icon': 'mdi-timer-alert', 'color': '#7dc4f8'},
        'NORMAL': {'icon': 'mdi-timer-alert', 'color': '#e8c373'},
        'HIGH': {'icon': 'mdi-timer-alert', 'color': '#fa933e'},
        'IMMEDIATE': {'icon': 'mdi-alarm-light', 'color': '#f40d0d'}
    }

    class ServiceOrderService {
        constructor($q, $http, $mdDialog, mnWebSocket, moment, $translate, system, $sce, configService) {
            this.q = $q;
            this.$sce = $sce;
            this.http = $http;
            this.dialog = $mdDialog;
            this.moment = moment;
            this.ws = mnWebSocket;
            this.translate = $translate;
            this.configService = configService;
            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;
            this.dateTimeFormat = system['datetime_format'].js;

            this.columnConfiguration = [
                {
                    key: "patient_full_name",
                    order_by: "patient.full_name",
                    label: "patient",
                    getContent: item => {
                        if (!item['patient']) return "<span></span>";

                        return `<div layout="row">
                                <strong>${item['patient']['full_name']}</strong>
                                                            
                            </div>`;
                    },
                    rowspan: 2,
                    "type": "text",
                    "is_searchable": true,
                    "is_global_searchable": true,
                    "is_ref": true,
                    "model": "Patient",
                    "module": "patient",
                    "filter_fields": [
                        {
                            "key": "first_name",
                            "order_by": "first_name",
                            "label": "first_name",
                            "type": "text",
                            "is_searchable": true,
                            "is_global_searchable": true,
                            "model": "Patient",
                            "module": "patient",
                            "is_ref": true
                        },
                        {
                            "key": "last_name",
                            "order_by": "last_name",
                            "label": "last_name",
                            "type": "text",
                            "is_searchable": true,
                            "is_global_searchable": true,
                            "model": "Patient",
                            "module": "patient",
                            "is_ref": true
                        }
                    ]
                },
                {
                    key: "created_date",
                    order_by: "created_at",
                    label: "created_at",
                    classes: "datetime-column",
                    getContent: item => {
                        if (!item['created_at']) return "<span></span>";

                        return `<span>${this.get_datetime_by_zone((item['created_at']))}</span>`;
                    },
                    rowspan: 2,
                    "type": "date",
                    "is_searchable": true,
                    "is_global_searchable": true
                },
                {
                    key: "created_by.full_name",
                    order_by: "created_by.full_name",
                    label: "service_order_workflow.created_by",
                    getContent: item => {
                        if (!item['created_by'] || !item['created_by']['full_name']) return "<span></span>";

                        return `<span>${item['created_by']['full_name']}</span>`;
                    },
                    rowspan: 2,
                    "type": "text",
                    "is_searchable": false,
                    "is_global_searchable": true
                },
                {
                    key: "prescriber",
                    order_by: "prescriber._ref.$id.pk",
                    label: "treating_physician",
                    getContent: item => {
                        if (!item['prescriber'] || !item['prescriber']['full_name']) return "<span></span>";

                        return `<span>${item['prescriber']['full_name']}</span>`;
                    },
                    "type": "text",
                    "is_searchable": true,
                    "is_global_searchable": true,
                    "is_ref": true,
                    "model": "PhysicianContact",
                    "module": "shared.staff",
                    "filter_fields": [
                        {
                            "key": "first_name",
                            "order_by": "prescriber.first_name",
                            "label": "first_name",
                            "type": "text",
                            "is_searchable": true,
                            "is_global_searchable": true,

                            "model": "PhysicianContact",
                            "module": "shared.staff",
                            "is_ref": true
                        },
                        {
                            "key": "last_name",
                            "order_by": "prescriber.last_name",
                            "label": "last_name",
                            "type": "text",
                            "is_searchable": true,
                            "is_global_searchable": true,

                            "model": "PhysicianContact",
                            "module": "shared.staff",
                            "is_ref": true
                        }
                    ]
                },
                {
                    key: "title",
                    order_by: "title",
                    label: "daily_workflow.reason", classes: "small-column",
                    getContent: item => {
                        if (!item['title']) return "<span></span>";

                        return ` <span style=" width: auto; margin: 0 6px" class="mn-badge">${item['title']}</span>  
 
                            <md-icon class="mdi ${PRIORITY_ICONS[item['priority']]['icon']}"
                                         style="color: ${PRIORITY_ICONS[item['priority']]['color']}; margin:unset">
                            <md-tooltip md-direction="bottom" md-delay="150">
                                <span>${this.translate.instant('priority')}: ${this.translate.instant('service_order_' + item['priority'])}</span>
                            </md-tooltip>
                           
                     `;
                    },
                    rowspan: 2,
                    "type": "text",
                    "is_searchable": true,
                    "is_global_searchable": true
                },
                {
                    key: "id",
                    order_by: "_id",
                    label: "service_order_id",
                    getContent: item => {
                        if (!item['id']) return "<span></span>";

                        return `<span>#${item['id']}</span>`;
                    },
                    "type": "number",
                    "is_searchable": false,
                    "is_global_searchable": false
                },
                {
                    key: "comment",
                    order_by: "comment",
                    label: "comment",
                    classes: "text-center medium-column ",
                    style: {"max-width": "200px", "min-width": "200px"},
                    getContent: item => {
                        if (!item['comment']) return "<span></span>";

                        return `<span>${item['comment']}</span>`;
                    },
                    getTooltip: item => {
                        if (!item['comment']) return "";

                        return item['comment'] || '';
                    },
                    colspan: 1,
                    "type": "text",
                    "is_searchable": true,
                    "is_global_searchable": true
                },
                {
                    key: "priority",
                    order_by: "priority",
                    label: "service_order_billing_status",
                    classes: "text-center",
                    getContent: item => {
                        return `
                              <md-icon class="mdi mdi-alpha-f-circle"  style="color: ${item.billing_details && item.billing_details.fully_billed ? '#1abc09' : 'unset'}">  </md-icon> 
                              <md-icon class="mdi mdi-alpha-p-circle"  style="color: ${item.payment_details && item.remaining_amount == 0 ? '#1abc09' : 'unset'}"    >  </md-icon> 
                        `;
                    },
                    "type": "text",
                    "is_searchable": false,
                    "is_global_searchable": false,
                    "choices": ['LOW', 'NORMAL', 'HIGH', 'IMMEDIATE']
                },
                {
                    key: "predicted_start_date",
                    order_by: "predicted_start_date",
                    label: "service_order_workflow.predicted_start_date",
                    classes: "datetime-column",
                    getContent: item => {
                        if (!item['predicted_start_date']) return "<span></span>";

                        return `<span>${item['predicted_start_date']}</span>`;
                    },
                    "type": "date",
                    "is_searchable": true,
                    "is_global_searchable": true
                },
                {
                    key: "predicted_end_date",
                    order_by: "predicted_end_date",
                    label: "service_order_workflow.predicted_end_date",
                    classes: "datetime-column",
                    getContent: item => {
                        if (!item['predicted_end_date']) return "<span></span>";
                        return `<span>${item['predicted_end_date']}</span>`;
                    },
                    "type": "date",
                    "is_searchable": true,
                    "is_global_searchable": true
                },
            ];
            this.subColumnConfiguration = [
                {
                    key: "created_at",
                    order_by: "created_at",
                    label: "created_at",
                    classes: "datetime-column",
                    getContent: item => {
                        if (!item['created_at']) return "<span></span>";

                        return `<span>${this.get_datetime_by_zone(item['created_at'])}</span>`;
                    },
                    shared_header: true,
                    "type": "date",
                    "is_searchable": true,
                    "is_global_searchable": false
                },
                {
                    key: "owner_name",
                    order_by: "owner.full_name",
                    label: "service_order_workflow.created_by",
                    getContent: item => {
                        if (!item['owner'] || !item['owner']['full_name']) return "<span></span>";

                        return `<span>${item['owner']['full_name']}</span>`;
                    },
                    shared_header: true,
                    "type": "text",
                    "is_searchable": false,
                    "is_global_searchable": false,

                    "model": "Staff",
                    "module": "shared.staff",
                    "is_ref": true,
                    "filter_fields": [
                        {
                            "key": "first_name",
                            "order_by": "owner.first_name",
                            "label": "first_name",
                            "type": "text",
                            "is_searchable": true,
                            "is_global_searchable": true,
                            "model": "Staff",
                            "module": "shared.staff",
                            "is_ref": true,
                        },
                        {
                            "key": "last_name",
                            "order_by": "owner.last_name",
                            "label": "last_name",
                            "type": "text",
                            "is_searchable": true,
                            "is_global_searchable": true,
                            "model": "Staff",
                            "module": "shared.staff",
                            "is_ref": true
                        }
                    ]
                },
                {
                    key: "assignee",
                    order_by: "assignee.full_name",
                    label: "medical_care.affected_to",
                    getContent: item => {
                        return `<span>${!_.isEmpty(item['assignee']) ? item['assignee']['full_name'].toUpperCase() : ''}</span>`;
                    },
                    "type": "text",
                    "is_searchable": false,
                    "is_global_searchable": false,
                },
                {
                    key: "reason",
                    order_by: "reason.description",
                    label: "daily_workflow.reason", classes: "small-column",

                    getContent: item => {
                        if (!item['reason']) return "<span></span>";

                        return `<span>${item['reason']['description']}</span>`;
                    },
                    shared_header: true,
                    "type": "text",
                    "is_searchable": true,
                    "is_global_searchable": false
                },
                {
                    key: "id",
                    order_by: "_id",
                    label: "service_order_workflow.ticket_id",
                    getContent: item => {
                        if (!item['id']) return "<span></span>";

                        return `<span>#${item['id']}</span>`;
                    },
                    "type": "number",
                    "is_searchable": false,
                    "is_global_searchable": false
                },
                {
                    key: "execution_date",
                    order_by: "execution_date",
                    label: "medical_care.scheduled_at",
                    classes: "datetime-column",
                    getContent: item => {
                        return `<span>${item['execution_date'] ? item['execution_date'] : ''} 
                            &nbsp;&nbsp;&nbsp; ${item['start_time'] ? this.moment(item['start_time'], 'HH:mm:ss').format('HH:mm') : ''} - 
                                ${item['end_time'] ? this.moment(item['end_time'], 'HH:mm:ss').format('HH:mm') : ''}</span>`;
                    },
                    "type": "date",
                    "is_searchable": true,
                    "is_global_searchable": false
                },
                {
                    key: "last_external_status",
                    order_by: "last_external_status",
                    label: "medical_care.status",
                    classes: "text-center small-column",
                    getContent: item => {
                        if (!item['reason']) return "<span></span>";

                        return `<span style="background:  ${TICKET_STATUS[item['last_external_status']] ? TICKET_STATUS[item['last_external_status']]['color'] : ''}"
                                class="mn-badge">
                                ${this.translate.instant(item['last_external_status'])}
                                </span>`;
                    },
                    "type": "text",
                    "is_searchable": true,
                    "is_global_searchable": false,
                    "choices": ["MISSED", "REJECTED", "ACCEPTED", "COMPLETED", "IN_PROGRESS", "ON_HOLD", "CANCELLED", "SCHEDULED", "RESCHEDULED"]
                },
                {
                    key: "done_at",
                    order_by: "done_at",
                    label: "service_order_workflow.done_at",
                    classes: "datetime-column",
                    getContent: item => {
                        if (!item['done_at']) return "<span></span>";

                        return `<span>${this.get_datetime_by_zone(item['done_at'])}</span>`;
                    },
                    "type": "date",
                    "is_searchable": true,
                    "is_global_searchable": false
                },
                {
                    key: "done_by",
                    order_by: "done_by.full_name",
                    label: "medical_care.done_by",
                    getContent: item => {
                        return `<span>${!_.isEmpty(item['done_by']) && item['done_by']['full_name'] ? item['done_by']['full_name'].toUpperCase() : ''}</span>`;
                    },
                    "type": "text",
                    "is_searchable": false,
                    "is_global_searchable": false
                },
            ];

            this.timeZone = this.configService.calendarConfig['time_zone']|| 'Africa/Casablanca';

        }

        static get $inject() {
            return ["$q", "$http", "$mdDialog", "mnWebSocket", "moment", "$translate", "system", "$sce", "configService"];
        }

        get_datetime_by_zone(dateUtc) {
            return this.moment.utc(dateUtc, this.dateTimeFormat).tz(this.timeZone).format(this.dateTimeFormat);
        }

        getColumnConfiguration() {
            return _.cloneDeep(this.columnConfiguration);
        }

        getSubColumnConfiguration() {
            return _.cloneDeep(this.subColumnConfiguration);
        }

        getHealthCareServiceOrders(query) {
            return this.ws.call("extranet.HealthCareServiceOrder.get_list", {query});
        }

        getServiceOrder(pk) {
            let deferred = this.q.defer();
            let url = `/api/en/healthcare-service-order/${pk}/`
            this.http.get(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        updateServiceOrder(pk, data) {
            let deferred = this.q.defer();
            let url = `/api/en/healthcare-service-order/${pk}/`
            this.http.put(url, data).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        handleTicket(data) {
            let deferred = this.q.defer();
            let url = `/api/en/healthcare-service-ticket/${!_.isNil(data.id) ? data.id + "/" : ""}`
            this.http.post(url, data).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        getTicket(pk) {
            let deferred = this.q.defer();
            let url = `/api/en/healthcare-service-ticket/${pk}/`
            this.http.get(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        cancelTicket(pk) {

            let deferred = this.q.defer();
            let url = `/api/en/healthcare-service-ticket/${pk}/`
            this.http.delete(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        getOrderMedicalCarePlan(orderId) {
            return this.ws.call("extranet.HealthCareServiceOrder.get_medical_care_plan", {orderId});
        }

        getTicketStatusConfig() {
            return TICKET_STATUS;
        }

        getPrioritiesConfig() {
            return PRIORITY_ICONS;
        }

        getExamReasons() {
            return this.ws.call("extranet.HealthCareServiceOrder.exam_reasons", {});
        }

        getTicketConsumables(pk) {
            let deferred = this.q.defer();
            let url = `/api/en/service-order-ticket-consumables/?ticket=${pk}`
            this.http.get(url).then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

    }

    module.exports = ServiceOrderService;

})();
